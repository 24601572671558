<template>
  <div class="layoutContent">
    <home-title
      :description="description"
      :showpic="
        getConfigVal(curPageConfigAndDefaultPageConfig, 'showPic', 'vIf')
      "
    />
    <div class="searchBox" :class="isWapSearch ? 'd-flex' : ''">
      <div class="searchInfo wapSearchInfo">
        <k-form-select
          v-model="queryParams.electionId"
          :options="electionList"
          style="max-width: 300px"
          class="mr-2 mb-1"
        />
        <k-form-select
          v-model="queryParams.jobId"
          :options="positionPreferenceList"
          style="max-width: 300px"
          class="mb-1"
        />
        <div class="wapFlexShow justify-content-between">
          <k-button @click="search" variant="primary">
            buttons.search
          </k-button>
          <k-button @click="wapSearch" variant="utility">
            buttons.cancel
          </k-button>
        </div>
      </div>
      <div class="searchBtn wapHide">
        <k-button @click="search" variant="primary" class="mr-2"
          >buttons.search</k-button
        >
        <k-button @click="clear" variant="default"> buttons.clear </k-button>
      </div>
    </div>
    <b-icon
      icon="search"
      class="wapShow featuredFont"
      @click="wapSearch"
      style="margin-bottom: 10px"
    ></b-icon>
    <div class="contentParty">
      <adaptiveTable
        :only-table-wap="true"
        :fields="fields"
        :items="tableWapData"
        v-show="!isWapSearch"
      />
      <konn-grid
        ref="selectableTable"
        :has-action="false"
        :has-check-box="false"
        :fields="fields"
        :data-source="dataSource"
        :get-condition="getCondition"
        @items-get="allGet"
        class="wapTableHide"
        v-show="!isWapSearch"
      >
        <template #cell(paymentAmount)="row">
          <span>{{ toThousands(row.value) }}</span>
        </template>
      </konn-grid>
    </div>
  </div>
</template>
<script>
import { getPortalPageSetting } from "@/api/home";
import {
  getCurPageConfigAndDefaultPageConfigbyRoutePath,
  getConfigVal,
  storeage,
} from "fe-infrastractures";
import {
  getPaymentList,
  getHistoryElectionList,
  getPositionPreferenceList,
} from "../../api/payment";
import { EnumWorkPortalStatus } from "@/utils/enums/electionStatus";
import { pageNameEnum } from "@/utils/enums/pageNameEnum";
import adaptiveTable from "../components/adaptive-table.vue";
import HomeTitle from "@/views/components/homeTitle";
export default {
  components: { adaptiveTable, HomeTitle },
  computed: {
    dataSource() {
      return getPaymentList;
    },
  },
  data() {
    let curPageConfigAndDefaultPageConfig =
      getCurPageConfigAndDefaultPageConfigbyRoutePath(
        "workerPortal",
        this.$route.path
      );
    return {
      curPageConfigAndDefaultPageConfig,
      getConfigVal,
      description: "",
      queryParams: {
        electionId: null,
        jobId: null,
      },
      electionList: [],
      positionPreferenceList: [],
      fields: [
        {
          key: "electionTitle",
          label: "electionTitle",
          sortable: false,
          width: "25%",
        },
        {
          key: "electionDateStr",
          label: "electionDate",
          sortable: false,
          width: "25%",
        },
        {
          key: "position",
          label: "position",
          sortable: false,
          width: "25%",
        },
        {
          key: "paymentAmount",
          label: "paymentAmount",
          sortable: false,
          width: "25%",
        },
      ],
      tableWapData: null,
      isWapSearch: false,
    };
  },
  methods: {
    clear() {
      this.queryParams.electionId = null;
      this.queryParams.jobId = null;
      this.$refs.selectableTable.refresh();
    },
    search() {
      this.isWapSearch = false;
      this.$refs.selectableTable.refresh();
    },
    getCondition() {
      return this.queryParams;
    },
    toThousands(num) {
      return num.toFixed(storeage.getDecimalPlacesOfMoney()).replace(/(\d)(?=(\d{3})+\.)/g, "$1,");
    },
    getDescription() {
      getPortalPageSetting(pageNameEnum.Payment).then((resp) => {
        if (resp.data) {
          this.description = resp.data.description;
        }
      });
    },
    bindElectionList() {
      getHistoryElectionList(EnumWorkPortalStatus.published).then((resp) => {
        console.log("resp", resp);
        this.electionList = resp.data || [];
        if (this.electionList.length > 0) {
          this.queryParams.electionId = this.electionList[0].value;
        }
      });
    },
    bindPositionPreferenceList() {
      getPositionPreferenceList().then((resp) => {
        this.positionPreferenceList = resp.data || [];
        if (this.positionPreferenceList.length > 0) {
          this.queryParams.jobId = this.positionPreferenceList[0].value;
        }
      });
    },
    allGet(data) {
      this.tableWapData = data;
    },
    wapSearch() {
      this.isWapSearch = !this.isWapSearch;
    },
  },
  created() {
    this.bindPositionPreferenceList();
    this.bindElectionList();
    this.getDescription();
  },
};
</script>
